import { StaffErrors, StaffManagementState } from '@models/staff.models'
import { Action, ActionType } from '@components/Staff/actions'
import { SiteStaff } from '@models/staff.models'

export const staffErrorReducer = (state: StaffErrors[], action: ActionType) => {
  switch (action.type) {
    case Action.REMOVE_STAFF: {
      const staffErrors = [...state]
      staffErrors.splice(action.payload.staffIndex, 1)
      return staffErrors
    }
    default:
      return state
  }
}

export const staffReducer = (state: SiteStaff, action: ActionType) => {
  switch (action.type) {
    case Action.STAFF_FIELD_CHANGE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    case Action.STAFF_ADD_ROLE:
    case Action.STAFF_REMOVE_ROLE:
      return {
        ...state,
        roles: roleReducer(state.roles, action),
      }
    default:
      return state
  }
}

export const staffListReducer = (state: SiteStaff[], action: ActionType) => {
  switch (action.type) {
    case Action.STAFF_FIELD_CHANGE:
    case Action.STAFF_ADD_ROLE:
    case Action.STAFF_REMOVE_ROLE: {
      const staff = [...state]
      staff[action.payload.staffIndex] = staffReducer(
        staff[action.payload.staffIndex],
        action
      )
      return staff
    }
    case Action.ADD_STAFF: {
      const staff = [
        ...state,
        {
          email: '',
          roles: ['monitor-devices'],
        },
      ]
      return staff
    }
    case Action.REMOVE_STAFF: {
      const staff = [...state]
      staff.splice(action.payload.staffIndex, 1)
      return staff
    }
    case Action.RESET_STAFF: {
      return []
    }
    default:
      return state
  }
}

export const roleReducer = (state: string[], action: ActionType) => {
  switch (action.type) {
    case Action.STAFF_REMOVE_ROLE: {
      const roles = [...state]
      roles.splice(roles.indexOf(action.payload.role), 1)
      return roles
    }
    case Action.STAFF_ADD_ROLE: {
      const roles = [...state]
      roles.push(action.payload.role)
      return roles
    }
    default:
      return state
  }
}

export const staffManagementReducer = (
  state: StaffManagementState,
  action: ActionType
) => {
  switch (action.type) {
    case Action.STAFF_FIELD_CHANGE:
    case Action.STAFF_ADD_ROLE:
    case Action.STAFF_REMOVE_ROLE:
    case Action.ADD_STAFF:
    case Action.REMOVE_STAFF:
    case Action.RESET_STAFF: {
      console.log('staffManagementReducer', action)
      return {
        ...state,
        staff: staffListReducer(state.staff, action),
        staffErrors: staffErrorReducer(state.staffErrors, action),
      }
    }
    default:
      return state
  }
}
