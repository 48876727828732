import { API_URL } from "@config/resourceUrls";
import { get } from '@lib/utils/http'

export async function getAccessList(resourceId: string, token: string): Promise<string[]> {
    const response = await get<string[]>(`${API_URL}/access/${resourceId}`, token)
    if(!response.parsedBody) {
        throw new Error('getAccessList - response body empty')
    }

    return response.parsedBody
}