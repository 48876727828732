import { useEffect, useState } from 'react'
import { Site } from '@models/api.models'
import { getSite } from '@lib/data/site.data'
import { useKeycloak } from '@providers/auth'
import { updateSiteStatus } from '@lib/data/site.data'
import { HttpError } from '@lib/utils/http'

type SiteState = {
  data: Site | null
  fetching: boolean
  error: string
}

export default (siteId: string) => {
  const { token } = useKeycloak()
  const [state, setState] = useState<SiteState>({
    data: null,
    fetching: false,
    error: '',
  })

  useEffect(() => {
    let subscribed = true

    getSite(token, siteId)
      .then((site) => {
        if (!subscribed) return
        setState({
          data: site,
          fetching: false,
          error: '',
        })
      })
      .catch((err) => {
        console.log(err)
        if (!subscribed) return

        if (err instanceof HttpError) {
          if (err.statusCode === 403) {
            setState({
              data: null,
              fetching: false,
              error: 'You do not have access to this site',
            })
          }
        } else {
          setState({
            data: null,
            fetching: false,
            error: err.message,
          })
        }
      })

    return () => {
      subscribed = false
    }
    // TODO: go look at useInvitation to9 improve this effect
  }, [siteId])

  function setSiteStatus(status: string) {
    updateSiteStatus(token, siteId, status).then((site) => {
      setState((prevState) => {
        return {
          ...prevState,
          data: site,
        }
      })
    })
  }

  return {
    data: state.data,
    fetching: state.fetching,
    error: state.error,
    setSiteStatus,
  }
}
