import { SiteStaff } from '@models/staff.models'

export enum Action {
  STAFF_FIELD_CHANGE = 'STAFF_FIELD_CHANGE',
  STAFF_ADD_ROLE = 'STAFF_ADD_ROLE',
  STAFF_REMOVE_ROLE = 'STAFF_REMOVE_ROLE',
  ADD_STAFF = 'ADD_STAFF',
  REMOVE_STAFF = 'REMOVE_STAFF',
  RESET_STAFF = 'RESET_STAFF',
}

type StaffFieldChange = {
  type: Action.STAFF_FIELD_CHANGE
  payload: { staffIndex: number; key: string; value: string }
}
type StaffRemoveRole = {
  type: Action.STAFF_REMOVE_ROLE
  payload: { staffIndex: number; role: string }
}
type StaffAddRole = {
  type: Action.STAFF_ADD_ROLE
  payload: { staffIndex: number; role: string }
}
type AddStaff = { type: Action.ADD_STAFF }
type RemoveStaff = {
  type: Action.REMOVE_STAFF
  payload: { staffIndex: number }
}

type ResetStaff = { type: Action.RESET_STAFF; payload: SiteStaff[] }

export type ActionType =
  | StaffFieldChange
  | StaffAddRole
  | StaffRemoveRole
  | AddStaff
  | RemoveStaff
  | ResetStaff
